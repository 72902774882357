<template>
	<div class="user-list van-row--flex van-row--justify-center van-cell--center">
		<div class="user-list-item" :class="{ me: item.oneself }" v-for="(item, index) in list" :key="index">
			<img :src="item.portrait" class="image" />
		</div>
		<!-- <template v-if="groupSuccess">
			<div class="user-list-item" v-if="isNumberExceedMax">
				<img :src="require('@/assets/images/group/people_list_ellipsis3.png')" class="image" />
			</div>
		</template>
		<template v-else>
			<div class="connect-symbol">
				<img :src="require('@/assets/images/group/people_icon_ellipsis.png')" class="image" />
			</div>
			<div class="user-list-item">
				<img :src="require('@/assets/images/group/people_list_other.png')" class="image" />
			</div>
		</template> -->
	</div>
</template>

<script>
export default {
	name: 'group-result-people',
	props: {
		list: {
			type: Array,
			default: [],
		},
		groupSuccess: Boolean,
	},
	computed: {
		maxShowNumber () {
			return this.groupSuccess ? 5 : 3
		},
		isNumberExceedMax () {
			return this.list.length > this.maxShowNumber
		},
	},
}
</script>

<style scoped lang="scss">
.user-list {
	margin-bottom: 0.53rem;

	&-item {
		@include margin_center(0.265rem);
		position: relative;
		width: 0.91rem;
		height: 0.91rem;
		background-color: #fff;

		.image {
			width: 0.91rem;
			height: 0.91rem;
			border-radius: 50%;
			overflow: hidden;
		}

		&.me:before {
			@include flex_center;
			position: absolute;
			top: -0.15rem;
			right: -0.1rem;
			content: '我';
			width: 0.32rem;
			height: 0.32rem;
			line-height: 0;
			background: #ff6a4d;
			border-radius: 0.02rem;
			font-size: 0.21rem;
			color: #fff;
		}
	}

	.connect-symbol {
		width: 0.35rem;
		@include margin_center(0.28rem);

		.image {
			width: 100%;
		}
	}
}
</style>
