<template>
  <div v-if="loadingComplete">
    <commonHeader :backURL="backURL" :showHeader="!isDeviceApp" />
    <orderDetaiGroupResult :record="record" :customer="groupInfo.customer" :qq_link="groupInfo.customer.qq_link"
      @finish="onFinish" />
    <!-- <div class="order-detail-card">
      <div class="title">商品信息</div>
      <div class="content">
        <orderGoodsInforCard :data="groupInfo" :showFialText="true"/>
      </div>
    </div> -->
    <div class="card " v-if="isDeviceApp && groupInfo.refund_status_text">
      <div class="card-title">商品信息</div>
      <div class="card-content ">
        <ul class="infor-list container">
          <li>
            <div class="infor-list-item">
              <p class="name">
                <span class="text">{{ groupInfo.group_title }}</span>
              </p>
              <p class="detail">
                <span class="text">￥{{ groupInfo.cost_payment }}</span>
                <span class="text">数量：1</span>
                <span class="refund-text" @click="toRefund(groupInfo.refund_url)">{{ groupInfo.refund_status_text
                }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="order-detail-card" v-if="express !== ''">
      <div class="title">物流信息</div>
      <div class="content">
        <orderDetailLogistics :express="express"></orderDetailLogistics>
      </div>
    </div>

    <div v-if="groupInfo.guide.status" class="order-detail-card">
      <div class="title">{{ groupInfo.guide.lable }}</div>
      <div class="content">
        <div class="tips">{{ groupInfo.guide.text }}</div>
        <div v-if="groupInfo.guide.img" class="image">
          <img :src="groupInfo.guide.img" />
        </div>
      </div>
    </div>

    <div class="order-detail-card">
      <div class="title">温馨提示</div>
      <div class="content">
        <div class="tips">
          如果您对订单有疑问或需要售后服务，请
          <a :href="dialogueURL" class="link">联系客服</a>
        </div>
      </div>
    </div>



    <!--拼团中-->
    <footerFixed v-if="record.status === 1">
      <!-- app内分享 -->
      <template v-if="isDeviceApp">
        <page-bottom-button color="red" buttonType="text" text="邀请好友参团" @click="clickShare" />
      </template>
      <!-- app外分享 -->
      <template v-else>
        <!--分享按钮-->
        <buttonShare text="邀请好友参团" @click="clickShare" />
      </template>

    </footerFixed>

    <!--拼团成功 且有品牌信息 || 有qq链接-->
    <footerFixed v-if="false">
      <!-- <footerFixed
        v-if="record.status === 2 && (goods.brand || groupInfo.customer.qq_link)"
    > -->
      <!--			<a-->
      <!--				:href="groupInfo.customer.qq_link"-->
      <!--				class="class-button"-->
      <!--				v-if="groupInfo.customer.qq_link"-->
      <!--				>加入班级群</a-->
      <!--			>-->

      <!--打开APP-->
      <buttonApp v-if="goods.brand" :brand="goods.brand" :province="goods.province" :type="goods.type"
        :no="goods.item_no" :showBrandName="true" @onClick="clickOpenApp" />
    </footerFixed>
    <class-modal :show="showClassModal" :modalData="groupInfo.popup" :showModal.sync="showClassModal"
      @clickButton="clickClassModalButton">
    </class-modal>
    <app-share-popup :show.sync="showAppShare" :share="appShareData" />
  </div>
</template>

<script>
import { apiGetOrderDetail } from '@/api/order'
import orderDetailLogistics from '@/components/order/detail/logistics'
import orderDetaiGroupResult from '@/components/order/detail/group-result'
import footerFixed from '@/components/page-footer-fixed'
import buttonApp from '@/components/page-button-app'
import buttonShare from '@/components/page-button-share'
import commonMixins from '@/mixins/common'
import ClassModal from '@/components/order/detail/class-modal.vue'
import { getDeviceVersionNumber, updateAppMeta } from '@/utils/appDevice'
import { isIOSBrowser } from '@/utils/browser'
import AppSharePopup from '@/components/app-share-popup.vue'
import PageBottomButton from '@/components/page-bottom-button.vue'

export default {
  components: {
    orderDetailLogistics,
    orderDetaiGroupResult,
    footerFixed,
    buttonApp,
    buttonShare,
    ClassModal,
    AppSharePopup,
    PageBottomButton,
  },
  mixins: [commonMixins],
  data() {
    return {
      groupInfo: { guide: {} },
      record: {},
      goods: {},
      express: '',
      groupList: [],
      dialogueURL: '',
      loadingComplete: false,
      backURL: '',
      appMetaList: {
        //定义meta信息
        share_title: '',
        share_description: '',
        share_image:
          'https://appresource-1253756937.cos.ap-chengdu.myqcloud.com/skb/wv/pingtuan/share.png',
        share_url: '',
      },
      showClassModal: false, //是否显示班级弹窗

      showAppShare: false,//显示app分享
    }
  },
  computed: {
    isDeviceApp() {
      return this.$store.getters.isDeviceAppClient
    },
    appShareData() {
      const share = this.appMetaList
      return {
        url: share.share_url,
        title: share.share_title,
        description: share.share_description,
        img: share.share_image
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        { property: 'tiku:share', content: true },
        { property: 'tiku:share_title', content: this.appMetaList.share_title },
        {
          property: 'tiku:share_description',
          content: this.appMetaList.share_description,
        },
        { property: 'tiku:share_image', content: this.appMetaList.share_image },
        { property: 'tiku:share_url', content: this.appMetaList.share_url },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('getDialogueURL').then((dialogueURL) => {
      this.dialogueURL = dialogueURL
    })
    this.getOrderDetail()

    this.backURL = this.getBackURL()
  },
  methods: {
    // 更新meta信息
    updateAppMetaList() {
      this.appMetaList.share_title = this.share_info.title
      this.appMetaList.share_description = this.share_info.subtitle
      this.appMetaList.share_url = this.getGooodDetailURL()

      //app分享时，需要调用此方法 延长调用，否则app拿不到数据
      setTimeout(() => {
        updateAppMeta()
      }, 1000)
    },
    getBackURL() {
      return window.location.origin + '/wap/order/list'
    },
    getGooodDetailURL() {
      let options = {
        id: this.groupInfo?.g_id,
        rid: this.groupInfo?.r_id,
      }
      return this.$util.getGoodsDetailURL(options)
    },
    clickShare() {
      //app内显示分享浮窗
      if (this.isDeviceApp) {
        this.showAppShare = true
        return false
      }
      let url = this.getGooodDetailURL()
      this.$util.replacePageState({}, '', url)

    },
    clickOpenApp() {
      let url = this.getGooodDetailURL() + '&alreadyBuy'
      this.$util.replacePageState({}, '', url)
    },
    getOrderDetail() {
      let param = {},
        query = this.$route.query
      param.id = query.id
      param.gid = query.gid
      param.rid = query.rid
      apiGetOrderDetail(param).then((res) => {
        let resData = res?.data

        // 用户未登录
        if (res.status === -1) {
          this.$router.push({ name: 'login' })
          return
        }
        // 不是自己的订单
        if (!resData?.is_my) {
          let url = '/wap/goods/detail?id=' + resData?.group_info?.g_id
          if (resData?.group_info?.rid) {
            url += '&rid=' + resData?.group_info?.r_id
          }
          window.location.replace(url)
        }

        this.groupInfo = resData.info
        this.record = resData.record
        this.goods = resData.goods
        this.express = resData.express
        this.share_info = resData.share_info
        this.groupList = resData.group_list
        let link = this.getGooodDetailURL(),
          share_info = resData.share_info
        let shareText = share_info.subtitle
          ? share_info.subtitle
          : `${share_info.group_num}人成团，仅需¥${this.$util.clearPriceZero(share_info.group_price)}，快来一起拼团吧。`

        if (this.groupInfo.customer.url) {
          this.dialogueURL = this.groupInfo.customer.url
        }

        if (share_info.title) {
          document.title = share_info.title
        }
        // 设置分享标题
        this.$util.setShare({
          title: share_info.title,
          desc: `${shareText}`,
          link,
        })
        this.updateAppMetaList()
        this.checkShowClassModal()
        this.loadingComplete = true
      })
    },

    onFinish() {
      let time = this.record.end_time - Date.parse(new Date()) / 1000 || 0
      setTimeout(() => {
        this.getOrderDetail()
      }, time + 500)
    },

    // 判断是否显示班级弹窗
    checkShowClassModal() {
      let show = this.$route.query.classmodal === 'true'
      // let show = true
      // 没有班级链接时不显示弹窗
      if (this.groupInfo.popup.status !== '1') {
        show = false
      }
      this.showClassModal = show
    },
    // 点击班级弹窗按钮
    clickClassModalButton() {
      const buttonOptions = this.groupInfo?.popup?.text?.button || {}

      // 加qq群
      if (buttonOptions.mode === '1') {
        this.classModalButtonMode1(buttonOptions)
        return
      }

      // 微信扫码
      if (buttonOptions.mode === '2') {
        this.classModalButtonMode2(buttonOptions)
      }
    },
    // 按钮模式1跳转
    classModalButtonMode1(buttonOptions) {
      let url = buttonOptions.url || buttonOptions.wx_url
      this.$util.locationHref(url)
    },
    // 弹窗按钮模式2点击
    classModalButtonMode2(buttonOptions) {
      let url = buttonOptions.wx_path //默认直接打开微信浏览器网址
      const version = this.$store.state.device.deviceAppVersion
      const mpCommand = buttonOptions.path
      console.log('app=' + this.isDeviceApp);
      console.log('app版本=' + version);
      console.log('app指令=' + mpCommand);
      if (this.isDeviceApp) {
        url = mpCommand
        // ios且版本号超过 103000054 时，跳转指令
        if (isIOSBrowser() && getDeviceVersionNumber(version) < 103000053) {
          url = buttonOptions.wx_path
        }
      }


      this.$util.locationHref(url)
    },
    toRefund(url) {
      const param = {
        type: "openUnit",
        option: {
          unitKey: "Web",
          param: {
            url: url,
            is_share: 0
          }
        }
      }

      window.location.href = 'urlaction://openUnit/CourseDetail?params=' + JSON.stringify(param)
    }
  },
}
</script>

<style scoped lang="scss">
.order-detail-card {
  padding: 0.43rem 0.53rem;
  background-color: #fff;
  margin-top: 0.2rem;

  .title {
    font-size: 0.43rem;
    font-weight: bold;
    color: #333333;
    padding-bottom: 0.43rem;
  }

  .image {
    margin-top: 0.4rem;
    text-align: center;
  }

  .tips {
    font-size: 0.35rem;
    color: #919499;
    white-space: pre-wrap;
  }
}

.course-list {
  .header {
    padding: 0.5rem 0 0rem;

    .title {
      font-size: 0.43rem;
      font-weight: bold;
      line-height: 1;
      color: #3c3d42;
      padding-top: 0.25rem;

      .icon {
        width: 0.08rem;
        height: 0.4rem;
        margin-right: 0.2rem;
      }
    }
  }
}

.link {
  color: #448aff;
}

.class-button {
  @include flex_center;
  flex: 1;
  height: 1.12rem;
  background: rgba($mainColor, 0.05);
  border: 1px solid rgba($mainColor, 0.5);
  border-radius: 1rem;
  font-size: 0.4rem;
  line-height: 0.56rem;
  color: $mainColor;

  &+.button {
    margin-left: 0.27rem;
    max-width: 4.3rem;
  }
}

.card {
  background-color: #fff;
  overflow: hidden;
  border-top: 0.32rem solid #f7f8fa;

  &-title {
    font-size: 0.43rem;
    line-height: 1;
    color: #333333;
    margin-top: 0.43rem;
    font-weight: bold;
    padding: 0 $containerPadding;
  }
}

.infor {
  &-list {
    >li+li {
      border-top: 1px solid #ebecf0;
    }

    &-item {
      padding: 0.5rem 0;

      .name {
        color: #333333;
        font-size: 0;
        margin-bottom: 0.2rem;

        >span {
          vertical-align: middle;
        }

        .lable {
          float: left;
          margin-top: 0.05rem;
          display: block;
          padding: 0 0.1rem;
          height: 0.45rem;
          line-height: 0.45rem;
          font-size: 0.29rem;
          border-radius: 0.05rem;
          background: #000103;
          color: #fff;
          margin-right: 0.2rem;
        }

        .text {
          font-size: 0.4rem;
          line-height: 0.56rem;
        }
      }

      .detail {
        .text {
          margin-right: 0.45rem;
          display: inline-block;
          font-size: 0.32rem;
          line-height: 0.45rem;
          color: #919499;
        }

        .refund-text {
          display: flex;
          justify-content: center;
          align-items: center;
          float: right;
          padding: 0 .32rem;
          width: max-content;
          height: 0.59rem;
          background: #FFFFFF;
          border-radius: 0.32rem 0.32rem 0.32rem 0.32rem;
          border: 0.01rem solid #E8E8E8;
          font-size: 0.29rem;
          color: #3C3D42;
          line-height: 0.56rem;
          transform: translateY(-.1rem);
        }
      }
    }
  }
}
</style>
