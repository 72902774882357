<template>
    <van-dialog v-model="showClassModal" :show-confirm-button="false" className="class-modal">
        <div class="modal-title">{{ modalClassData.title }}</div>
        <div class="modal-subtitle">{{ modalClassData.subtitle }}</div>
        <ul class="menu-list">
            <li v-for="(item, index) in modalClassData.menu" :key="index">
                <div class="menu-list-item" :style="'background:' + item.bg_color">
                    <img :src="item.img" class="icon">
                    <p class="text" :style="'color:' + item.font_color">{{ item.title }}</p>
                </div>
            </li>
        </ul>
        <van-button round type="primary" class="button" @click="clickButton">{{ modalClassData.button.title }}
        </van-button>
        <p class="close-text" @click="closeModal">关闭</p>
    </van-dialog>
</template>
<script>
export default {
    props: {
        show: Boolean,
        modalData: Object
    },
    data () {
        return {
        }
    },
    computed: {
        showClassModal: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:showModal', val)
            }
        },
        modalClassData () {
            return this.modalData?.text || {}
        }
    },
    methods: {
        closeModal () {
            this.showClassModal = false
        },
        clickButton () {
            this.$emit('clickButton')
        }
    },
}
</script>
<style scoped lang="scss">
.class-modal {
    border-radius: .32rem;
    padding-top: .64rem;
    padding-left: .53rem;
    padding-right: .53rem;
    box-sizing: border-box;
    width: 7.87rem;
}

.modal-title {
    font-size: 0.53rem;
    color: #3C3D42;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    margin-bottom: .1rem;
}

.modal-subtitle {
    font-size: 0.37rem;
    line-height: 0.8rem;
    color: #666666;
    line-height: 1.5;
    text-align: center;
    margin-bottom: .43rem;
}

.button {
    @include margin_center;
    display: block;
    width: 4.67rem;
    font-size: 0.37rem;
    background-color: #13BF80;
    border: 1px solid #13BF80;
    margin-bottom: .32rem;
    margin-top: .85rem;
    height: 1.07rem;
}

.menu-list {
    @include grid-ul(2, .13rem);

    li {
        &:nth-child(n+3) {
            .menu-list-item {
                margin-top: .13rem;
            }
        }
    }

    &-item {
        display: flex;
        align-items: center;
        height: 1.33rem;
        border-radius: 0.11rem;
        padding-left: .59rem;
        box-sizing: border-box;

        .icon {
            width: 0.69rem;
            height: 0.69rem;
            margin-right: .21rem;
        }

        .text {
            font-size: 0.32rem;
            font-weight: 400;
            line-height: 0.53rem;
            color: #EB9239;
        }
    }
}

.close-text {
    font-size: 0.32rem;
    color: #C2C5CC;
    line-height: 1;
    margin-bottom: .32rem;
    text-align: center;
}
</style>