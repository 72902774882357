<template>
  <div class="logistics">
    <div class="logistics-item">
      <div class="header">
        <img src="../../../assets/images/logistics/local.png" class="image">
      </div>
      <div class="content">
        <div class="name">
          <div class="text">{{ express.consignee }}</div>
          <div class="text">{{ express.phone }}</div>
        </div>
        <div class="detail">{{ express.address.text }} {{ express.address.address }}</div>
      </div>
    </div>
    <div class="logistics-item">
      <div class="header">
        <img src="../../../assets/images/logistics/car.png" class="image">
      </div>
      <div class="content">
        <div class="name">
          <div class="text">物流单号</div>
          <div class="text state">{{ express.delivery.logistics_order ? express.delivery.logistics_order : '暂未发货' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "logistics",
  props: ['express']
}
</script>

<style scoped lang="scss">
.logistics {
  &-item {
    display: flex;

    & + .logistics-item {
      margin-top: .4rem;
    }

    .header {
      width: .48rem;
      margin-right: .1rem;

      .image {
        width: 100%;
      }
    }

    .content {
      flex: 1;

      .name {
        display: flex;
        align-items: center;

        .text {
          font-size: 0.37rem;
          font-weight: bold;
          color: #18191A;
          margin-right: .53rem;
          line-height: .48rem;

          &.state {
            font-weight: 400;
            color: #3C3D42;
          }
        }
      }

      .detail {
        margin-top: .2rem;
        font-size: 0.32rem;
        color: #3C3D42;
      }
    }
  }
}
</style>
