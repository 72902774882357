import request from '@/utils/request'

//  交易准备
export function apiOrderGetList (data) {
    return request({
        url: 'order/getList',
        method: 'post',
        data: data,
    })
}



// 创建订单
export function orderCreatePayment (data) {
    return request({
        url: 'order/payment',
        method: 'post',
        data: data,
    })
}

// 查询支付订单结果
export function apiQueryPaymentResult (data) {
    return request({
        url: 'order/query',
        method: 'post',
        data: data,
    })
}


// 获取订单预支付信息
export function apiGetOrderPrepare(data) {
    return request({
        url: 'order/prepare',
        method: 'post',
        data: data,
    })
}

// 获取订单详情
export function apiGetOrderDetail(data) {
    return request({
        url: 'order/detail',
        method: 'post',
        data: data,
    })
}




