<template >
    <div>
        <van-popup v-model="showPopup" round position="bottom" :style="{ height: '7.6rem' }">
            <van-grid :border="false" :column-num="4" class="share-list">
                <van-grid-item class="share-list-item" v-for="(item, index) in shareList" :key="index"
                    @click="clickShare(item.type)">
                    <van-image :src="shareImagePrefix + item.icon" class="icon" />
                    <p class="text">{{ item.name }}</p>
                </van-grid-item>
            </van-grid>
            <div class="bottom-button" @click="cancel">取消</div>
        </van-popup>
    </div>
</template>
<script>
export default {
    props: {
        show: Boolean,
        share: Object
    },
    data () {
        return {
            shareImagePrefix: 'https://appresource-1253756937.cos.ap-chengdu.myqcloud.com/skb/widget/icon/',
            shareList: [
                {
                    name: '微信好友',
                    icon: 'share_menu_wx.png',
                    type: 'WXFriend'
                }, {
                    name: '朋友圈',
                    icon: 'share_menu_wxcircle.png',
                    type: 'WXCircle'
                }, {
                    name: 'QQ空间',
                    icon: 'share_menu_qqzone.png',
                    type: 'QQZone'
                }, {
                    name: 'QQ好友',
                    icon: 'share_menu_qq.png',
                    type: 'QQFriend'
                }, {
                    name: '新浪微博',
                    icon: 'share_menu_weibo.png',
                    type: 'Weibo'
                }, {
                    name: '复制链接',
                    icon: 'share_menu_copylink.png',
                    type: 'CopyLink'
                }
            ]
        }
    },
    computed: {
        showPopup: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        }
    },
    methods: {
        cancel () {
            this.showPopup = false
            // urlaction://openUnit/doFunction?params=
        },
        clickShare (shareType) {
            const optionParam = this.getUrlactionParamsOptionParam(this.share)
            optionParam.type = shareType
            const urlaction = 'urlaction://openUnit/doFunction?params=' + this.getShareUrlactionParams(optionParam)
            this.$util.locationHref(urlaction)
        },
        // 获取app指令参数
        getShareUrlactionParams (optionParam) {
            const shareParams = {
                type: "doFunction",
                option: {
                    unitKey: "Web",
                    function: "shareInvite",
                    param: optionParam
                }
            }
            return JSON.stringify(shareParams)
        },
        // 获取app指令具体参数
        getUrlactionParamsOptionParam (param) {
            return {
                url: param.url,
                title: param.title,
                description: param.description,
                img: param.img
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.share-list {
    padding: .75rem 0;

    &-item {
        &:nth-child(n+5) {
            margin-top: .53rem;
        }
    }

    .icon {
        width: 1.47rem;
    }

    .text {
        font-size: 0.32rem;
        line-height: 1;
        font-weight: 500;
        color: #22242E;
        margin-top: 0.27rem;
    }

    ::v-deep {
        .van-grid-item__content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }


}

.bottom-button {
    @include flex_center;
    border-top: 1px solid #EBECF0;
    height: 1.37rem;
    font-size: 0.43rem;
    font-weight: 500;
    color: #22242E;
}
</style>