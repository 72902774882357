<template>
  <div class="group-infor" v-if="record.list">
    <!--拼团中-->
    <template v-if="record.status === 1">
      <div class="group-timer">
        <p class="text">成团有效时间剩余</p>
        <dayTimer :time="dayTime" @finish="onFinish"></dayTimer>
      </div>
      <van-divider class="divider">{{ record.group_num }}人团，还有 <span class="number">{{ record.group_num -
          record.list.length
      }}</span>个名额
      </van-divider>
      <orderDetailGroupResultPeople :list="record.list" :groupSuccess="false" />
      <div class="tips">赶紧邀请你的小伙伴参与拼团吧！</div>
    </template>

    <!--拼团成功-->
    <template v-else-if="record.status === 2">
      <van-divider class="divider">{{ record.group_num }}人团，<span class="state--success">拼团成功</span></van-divider>
      <orderDetailGroupResultPeople :list="record.list" :groupSuccess="true" />

    </template>

    <!--拼团失败-->
    <template v-else>
      <van-divider class="divider">{{ record.group_num }}人团，<span class="state--fail">拼团失败</span></van-divider>
      <orderDetailGroupResultPeople :list="record.list" :groupSuccess="false" />
      <div class="tips">成团有效时间已过，还差{{ record.group_num - record.list.length }}人成团</div>
    </template>
    <a :href="qq_link" v-if="qq_link" class="class-button">{{ customer.qq_label }}</a>
  </div>
</template>

<script>
import dayTimer from "@/components/group-day-timer";
import orderDetailGroupResultPeople from '@/components/order/detail/group-result-people'

export default {
  name: "group-result",
  components: {
    dayTimer,
    orderDetailGroupResultPeople
  },
  props: ['record', 'qq_link', 'customer'],
  computed: {
    dayTime () {
      let time = this.record.end_time - Date.parse(new Date()) / 1000
      return time >= 0 ? time : 0
    },
  },
  methods: {
    onFinish () {
      this.$emit('finish');
    }
  },
}
</script>

<style scoped lang="scss">
.van-divider {
  justify-content: center;
  margin-top: .4rem;

  &:before,
  &:after {
    max-width: 1.6rem;
  }
}

.group-infor {
  background-color: #fff;
  overflow: hidden;

  .tips {
    font-size: 0.32rem;
    line-height: 0.32rem;
    color: #919499;
    text-align: center;
    margin-bottom: .53rem;
  }
}

.divider {
  font-size: .43rem;
  color: #3C3D42;

  .state--success {
    color: #13BF80
  }

  .state--fail,
  .number {
    color: #FF6A4D;
  }
}

.group-timer {
  @include flex_center;
  @include margin_center($containerPadding);
  height: 1.07rem;
  background: #F7F8FA;
  border-radius: .2rem;

  .text {
    font-size: 0.37rem;
    color: #3C3D42;
    margin-right: .27rem;

  }
}

.class-button {
  @include flex_center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 8rem;
  height: 1.12rem;
  background: rgba($mainColor, 0.05);
  border: 1px solid rgba($mainColor, 0.5);
  border-radius: 1rem;
  font-size: 0.4rem;
  line-height: 0.56rem;
  color: $mainColor;
  margin: 0 auto .53rem;
  box-sizing: border-box;
  padding: 0 .8rem;
}
</style>
